import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/HomekitAlexaList';
export const _frontmatter = {
  "title": "Amazon Alexa über Monocle",
  "path": "/Software/Other_Platforms/Amazon_Alexa_Skill_via_Monocle/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "In diesem Video zeigen wir Ihnen wie Sie mit der Plattform Monocle ganz einfach eine INSTAR IP Kamera so einbinden können, das diese über Amazon Alexa das Videobild abrufen kann. Lassen Sie sich so mit Sprachbefehlen das aktuelle Videobild auf Ihrem Alexa Echo Spot oder Show anzeigen.",
  "image": "P_SearchThumb_Synology.png",
  "social": "/images/Search/P_SearchThumb_Synology.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_Alexa_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Amazon Alexa über Monocle' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='In diesem Video zeigen wir Ihnen wie Sie mit der Plattform Monocle ganz einfach eine INSTAR IP Kamera so einbinden können, das diese über Amazon Alexa das Videobild abrufen kann. Lassen Sie sich so mit Sprachbefehlen das aktuelle Videobild auf Ihrem Alexa Echo Spot oder Show anzeigen.' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/Amazon_Alexa_Skill_via_Monocle/' locationFR='/fr/Software/Other_Platforms/Amazon_Alexa_Skill_via_Monocle/' crumbLabel="Monocle" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <EuiSpacer size="xl" mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer size="s" mdxType="EuiSpacer" />
    <h3 {...{
      "id": "amazon-alexa-über-monocle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#amazon-alexa-%C3%BCber-monocle",
        "aria-label": "amazon alexa über monocle permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Amazon Alexa über Monocle`}</h3>
    <div className="onecard">
  <a href="/de/Software/Other_Platforms/Video/Amazon_Alexa_Skill_via_Monocle/">
    <div className="image">
        <img alt="Amazon Alexa Skill via Monocle" width="715" height="536" border="10" src="/de/images/Software/Youtube_Alexa_Skill_Monocle.png" />
    </div>
  </a>
    <div className="action">
        <p>In diesem Video zeigen wir Ihnen wie Sie mit der Plattform Monocle ganz einfach eine INSTAR IP Kamera so einbinden können, das diese über Amazon Alexa das Videobild abrufen kann. Lassen Sie sich so mit Sprachbefehlen das aktuelle Videobild auf Ihrem Alexa Echo Spot oder Show anzeigen. Sie finden eine Liste der <a href="https://forum.monoclecam.com/category/37/instar" target="_blank" rel="noopener noreferrer">kompatiblen Kamera Modelle</a> auf der Seite des Herstellers.</p>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      